.SectionStory .content {
  text-align: center;
}

.SectionStoryBurn .pictures .arrows {
  margin-top: 10px;
}
.SectionStoryBurn .pictures {
  margin-top: 0% !important;
  padding: 2%;
  background-color: whitesmoke;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.SectionStory .pictures .arrows {
  margin-top: 70px;
}
.SectionStory .pictures .arrows img {
  margin: 30px 0;
}
.SectionStory .pictures .text {
  margin-top: 100px;
  text-align: center;
}
.SectionStoryBurn .pictures .text {
  margin-top: 10px;
  text-align: center;
}
@media (max-width: 1200px) {
  .SectionStory .pictures .arrows img {
    margin: 15px 0;
    max-width: 100px;
  }
  .SectionStory .pictures .text {
    margin-top: 50px;
  }
}
@media (max-width: 992px) {
  .SectionStory .pictures {
    margin-top: 50px;
  }
  .SectionStory .pictures .arrows img {
    max-width: 70px;
  }
}
@media (max-width: 767px) {
  .SectionStoryBurn {
    visibility: hidden;
    display: none;
    clear: both;
  }
  .SectionStory .pictures {
    margin-top: 30px;
  }
  .SectionStory .pictures .arrows {
    margin-top: 35px;
  }
  .SectionStory .pictures .arrows img {
    margin: 5px 0;
    max-width: 100%;
  }
  .SectionStory .pictures .text {
    margin-top: 30px;
  }
}
