.masonry {
  width: 90vw !important;
  border-radius: 20px !important;
}

@media (max-width: 600px) {
  .masonry {
    width: 90vw !important;
  }
}

@media (min-width: 1000px) {
  .masonry {
    width: 80vw !important;
  }
}
@media (min-width: 200px) {
  .gridLayout {
    padding: 10px !important;
  }
}

.mintAddyHeader {
  font-size: 16px !important;
  margin-bottom: 20px !important;
  margin: 10px !important;
  font-family: Agrandir !important;
  text-align: center;
}
.mintAddy {
  font-size: 16px !important;
  margin-bottom: 20px !important;
  margin: 10px !important;
  word-break: break-all;
  font-family: Agrandir !important;
  text-align: center;
}
.mintCard {
  /* max-width: 345px; */
  /* border-radius: 10px!important; */
  /* border: 2px solid black; */
}
.mintCard p{
    font-style: italic;
    color: rgba(138, 138, 138, 0.479);
}
.mintCard div{
    color: black;
    font-style:italic
}
/* .mintCard:hover{
} */
.mintBtn {
  font-size: 18px !important;
  width: 100%;
  height: 45px;
  border-radius: 0% !important;
  background-color: black !important;
  font-family: Agrandir !important;
  text-align: center;
}
.cardContent {
  padding: 0px !important;
  font-family: Agrandir !important;
}
.gridLayout {
  padding: 30px !important;
  margin: 0px !important;
  margin-left: -16px !important;
}
.headerText {
  display: flex;
  justify-content: center;
  /* width: 95%; */
  /* margin: auto; */
  /* border-radius: 10px; */
  /* border: 1px solid black; */
  padding: 5px;
  /* background: rgb(231, 231, 231)!important; */
  background: #dd7533;
}

.MuiButtonBase-root:focus {
    outline: none;
}
.wallet-adapter-dropdown-list-item{
    margin-left: 0;
}