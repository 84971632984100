footer {
    z-index: 9;
    margin-top: 80px;
    position: relative;
    padding: 14px 0;
    background: #000000;
    color: #ffffff;
    font-family: Agrandir !important;
}

footer .copyright {
    display: flex;
    justify-content: center;
    /* text-align: center; */
    /* padding: 0 150px; */
    font-weight: 700
}

footer .soclinks {
    /* position: flex; */
    /* top: 70%; */
    /* right: 15px; */
    /* left: 50%; */
    justify-content: center;    
    /* -webkit-transform: translateX(-22px);
    transform: translateX(-22px) */
}

footer .soclinks li {
    position: relative;
    /* margin-left: 30px; */
}

footer .soclinks li a {
    text-transform: uppercase;
    font-weight: 500;
    /* background: #fff; */
    color: white;
    width: 44px;
    height: 44px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    border-radius: 50%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

@media (max-width: 767px) {
    footer {
        padding: 20px 0 20px 0;
        margin-top: 50px
    }

    footer .copyright {
        padding: 0
    }

    footer .soclinks {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        right: 0;
        left: 0
    }

    footer .soclinks li {
        margin-left: 10px;
        margin-right: 10px
    }

    footer .soclinks li a {
        width: 24px;
        height: 24px;
        padding: 5px;
        vertical-align: middle
    }
}